@use '@angular/material' as mat;
@include mat.core;

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/icons.woff2') format('woff2');
}

@font-face {
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/symbols.woff2') format('woff2');
}

@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/font-300.woff2') format('woff2');
}

@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/font-400.woff2') format('woff2');
}

@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/font-600.woff2') format('woff2');
}

@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/font-700.woff2') format('woff2');
}

$primary-scheme: (
  50 : #fffbe9,
  100 : #fff5c8,
  200 : #ffeea3,
  300 : #fee77e,
  400 : #fee262,
  500 : #fedd46,
  600 : #fed93f,
  700 : #fed437,
  800 : #fecf2f,
  900 : #fdc720,
  A100 : #fff,
  A200 : #fff,
  A400 : #fff2ce,
  A700 : #ffebb4,
  contrast: (
    50 : #000,
    100 : #000,
    200 : #000,
    300 : #000,
    400 : #000,
    500 : #000,
    600 : #000,
    700 : #000,
    800 : #000,
    900 : #000,
    A100 : #000,
    A200 : #000,
    A400 : #000,
    A700 : #000,
  )
);
$accent-scheme: (
  50 : #e0f2e2,
  100 : #b3e0b6,
  200 : #80cb85,
  300 : #4db654,
  400 : #26a62f,
  500 : #00960a,
  600 : #008e09,
  700 : #008307,
  800 : #007905,
  900 : #006803,
  A100 : #97ff97,
  A200 : #64ff64,
  A400 : #31ff31,
  A700 : #18ff18,
  contrast: (
    50 : #000,
    100 : #000,
    200 : #000,
    300 : #000,
    400 : #fff,
    500 : #fff,
    600 : #fff,
    700 : #fff,
    800 : #fff,
    900 : #fff,
    A100 : #000,
    A200 : #000,
    A400 : #000,
    A700 : #000,
  )
);
$warn-scheme: (
  50 : #f7e0e1,
  100 : #ecb3b4,
  200 : #df8082,
  300 : #d24d50,
  400 : #c8262b,
  500 : #be0005,
  600 : #b80004,
  700 : #af0004,
  800 : #a70003,
  900 : #990001,
  A100 : #ffc4c4,
  A200 : #ff9191,
  A400 : #ff5e5e,
  A700 : #ff4545,
  contrast: (
    50 : #000,
    100 : #000,
    200 : #000,
    300 : #fff,
    400 : #fff,
    500 : #fff,
    600 : #fff,
    700 : #fff,
    800 : #fff,
    900 : #fff,
    A100 : #000,
    A200 : #000,
    A400 : #000,
    A700 : #fff,
  )
);
$primary-palette: mat.define-palette($primary-scheme);
$accent-palette: mat.define-palette($accent-scheme);
$warn-palette: mat.define-palette($warn-scheme);


@mixin set-typography($typography: null) {
  @if not $typography {
    $typography: mat.define-typography-config(
      $font-family: 'Titillium Web',
      $headline-1: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em), // CSS class .mat-headline-1
      $headline-2: mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em), // CSS class .mat-headline-2
      $headline-3: mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em), // CSS class .mat-headline-3
      $headline-4: mat.define-typography-level(34px, 40px, 400), // CSS class .mat-headline-4
      $headline-5: mat.define-typography-level(45px, 48px, 400), // h1
      $headline-6: mat.define-typography-level(32px, 38px, 600), // h2
      $subtitle-1: mat.define-typography-level(24px, 31.2px, 600), // h3
      $body-1: mat.define-typography-level(18px, 24px, 400), // h4
      $body-2: mat.define-typography-level(16px, 22px, 400), // body text
      $subtitle-2: mat.define-typography-level(18px, 21px, 400), // CSS class .mat-subtitle-2
      $caption: mat.define-typography-level(14px, 18px, 400), // CSS class .mat-caption
      $button: mat.define-typography-level(14px, 22px, 400, 'Titillium Web', normal) // bolder body
    );
  }

  $my-theme: mat.define-light-theme((
    color: (
      primary: $primary-palette,
      accent: $accent-palette,
      warn: $warn-palette
    ),
    typography: $typography,
  ));

  @include mat.all-component-themes($my-theme);
  @include mat.typography-hierarchy($typography);
}

.spb-dark-theme {
  $dark-theme: mat.define-dark-theme((
    color: (
      primary: $primary-palette,
      accent: $accent-palette,
      warn: $warn-palette
    ),
  ));

  @include mat.all-component-colors($dark-theme);
}

.material-icons {
  direction: ltr;
  display: inline-block;
  font-family: 'Material Icons', serif;
  font-feature-settings: 'liga';
  font-size: 24px;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
}

.material-symbols-outlined {
  direction: ltr;
  display: inline-block;
  font-family: 'Material Symbols Outlined', serif;
  font-feature-settings: 'liga';
  font-size: 24px;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
}
