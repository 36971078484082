@use '@sparbanken-syd/sparbanken-syd-theme/theme' as spb;
@include spb.set-typography;

// Remove annoying 5px margin
body, html {
  margin: 0;
}

// We cannot, ever, have blue links.
a, a:visited {
  color: #232323;
}

// The modified sparbanken button
.sparbanken-button {
  margin-top: 26px;
  background: #FFDF00;
  border-radius: 30px;
  border: 1px solid #FFDF00;
  padding: 5px 35px;
  font: 600 16px / 27px "Titillium Web", sans-serif;
  transition: all 0.2s;

  // Style for disabled sparbanken button
  &:disabled {
    background-color: #E0E0E0;
    border-color: #E0E0E0;
    cursor: not-allowed;
  }

  // Hover effect for enabled sparbanken button
  &:not(:disabled):hover {
    background-color: #FFEA80;
    border-color: #FFEA80;
    cursor: pointer;
  }
}

// Disabled readonly form-fields, for example name and personalNumber
.disabled-gray-background .mat-mdc-text-field-wrapper {
  background-color: #f5f5f5;
}
